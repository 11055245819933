import React from "react";
import styles from "./Footer.module.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as FacebookSvg } from "../../assets/facebook.svg";
import { ReactComponent as InstagramSvg } from "../../assets/instagram.svg";

const SingleColumn = () => {
  const { t } = useTranslation(["common"]);
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <div className={styles.column}>
      <h2>{t("followUs.title")}</h2>
      <div className={styles.svgContainer}>
        <FacebookSvg className={styles.svg} />
        <InstagramSvg className={[styles.svg, styles.svgIg].join(" ")} />
      </div>
      <small>
        &copy; Copyright 2022-{currentYear},{" "}
        <a
          className={styles.author}
          href="https://www.linkedin.com/in/pawe%C5%82-bartosik-2b3713170/"
        >
          Paweł Bartosik
        </a>
      </small>
    </div>
  );
};
const ContactData = ({ title, phoneNumber, email, address }) => {
  return (
    <div className={styles.column}>
      <h2>{title}</h2>
      <p className={styles.contactInfo}>Korespondencja: {phoneNumber}</p>
      <p className={styles.contactInfo}>E-mail: {email}</p>
      <p className={styles.contactInfo}>{address}</p>
    </div>
  );
};
const DoubleColumn = ({ title, data }) => {
  return (
    <div className={styles.column}>
      <h2>{title}</h2>
      {data.map((item, index) => (
        <p key={index} className={styles.contactInfo}>
          {item.label}: {item.value}
        </p>
      ))}
    </div>
  );
};

const Footer = () => {
  const contactInfoData = [
    { label: "NIP", value: "6070066740" },
    { label: "REGON", value: "301469936" },
    {
      label: "KONTO",
      value: "SANTANDER 23 1090 1317 0000 0001 1437 2833",
    },
    { label: "KRS", value: "0000359859" },
  ];
  return (
    <div className={styles.footer}>
      <ContactData
        title="WOPR CHODZIEŻ"
        phoneNumber="Skrytka pocztowa 18, 64-800 Chodzież"
        email="chodzieskiewopr@wp.pl"
        address="Stanisława Staszica-Stanica WOPR, 64-800 Chodzież"
      />
      <DoubleColumn title="DANE KONTAKTOWE" data={contactInfoData} />
      <SingleColumn />
    </div>
  );
};
export default Footer;
