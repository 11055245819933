import React from "react";
import styles from "./ContactModal.module.css";
import { useTranslation } from "react-i18next";

const ContactItem = (props) => {
  const { t } = useTranslation(["common"]);
  return (
    <div className={styles.staffItem}>
      <div className={styles.contentContainer}>
        <h3>Adres:</h3>
        <p>Stanisława Staszica-Stanica WOPR 64-800 Chodzież</p>
        <p>Skrytka pocztowa 18, 64-800 Chodzież</p>
        <h2 className={styles.h3ContainerContent}>Kontakt:</h2>
        <p>
          <b>Email:</b> chodzieskiewopr@wp.pl{" "}
        </p>
        <p>
          <b>Telefon kontaktowy:</b> +48 504 679 470{" "}
        </p>
        <p>
          <b>Facebook:</b>{" "}
          <a href="https://www.facebook.com/WOPRChodziez">
            https://www.facebook.com/WOPRChodziez
          </a>
        </p>
        <p>
          <b>Instagram:</b>{" "}
          <a href="https://www.instagram.com/chodzieskiewopr">
            https://www.instagram.com/chodzieskiewopr
          </a>
        </p>
      </div>
      <div className={styles.mapContainer}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2401.6679119814357!2d16.924392911657367!3d52.990373876357815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4703f3ba4d2abae7%3A0x6191ce550aee7224!2sSTANICA%20Odzia%C5%82u%20Miejskiego%20WOPR!5e0!3m2!1spl!2spl!4v1692098030565!5m2!1spl!2spl"
          width="330"
          height="450"
          style={{
            border: "0",
          }}
          allowFullScreen=""
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className={styles.blueContainer}>
        <p>
          <b>Chcesz zadać pytanie?</b> Zobacz czy nie znajduje się w naszej
          bazie FAQ – często zadawanych pytań. Znajdziesz ją na dole strony.
        </p>
      </div>
      <div className={styles.contentContainerExtra}>
        <h2 className={styles.h3ContainerContent}>Dodatkowe informacje:</h2>
        <p>
          <b>NIP:</b> 6070066740{" "}
        </p>
        <p>
          <b>REGON:</b> 301469936{" "}
        </p>
        <p>
          <b>KONTO BANKOWE:</b>
          <br></br> SANTANDER 23 1090 1317 0000 0001 1437 2833{" "}
        </p>
        <p>
          <b>KRS:</b> 0000359859{" "}
        </p>
        <h2 className={styles.h3ContainerContent}>Kontakt bezpośredni:</h2>
        <p>
          <b>Maciej Bielecki:</b>
          <br></br>Prezes Zarządu WOPR Chodzież <br></br> <b>Telefon:</b> +48
          504 679 470{" "}
        </p>
        <p>
          <b>Weronika Nowak:</b>
          <br></br>Sekretarz WOPR Chodzież <br></br> <b>Telefon:</b> +48 882 812
          945{" "}
        </p>
        <p>
          <b>Krzysztof Majewski:</b>
          <br></br>Skarbnik WOPR<br></br> <b>Telefon:</b> +48 600 930 952{" "}
        </p>
      </div>
      <div className={styles.faqContainer}>
        <h3>FAQ - częste zadawane pytania</h3>
        <p>
          <b>Jak dokonywać płatności w biurze WOPR? </b>
          <br></br> Aby wykonać płatność gotówką w biurze WOPR należy się
          przedwcześnie skontaktować z skarbnikiem. Kontakt do skarbnika
          dostępny jest w sekcji "Kontakt bezpośredni".
        </p>
        <p>
          <b>Ile wynosi składka członkowska WOPR?</b>
          <br></br> Składka członkowska wynosi 70 zł, a jej opłacenie jest
          możliwe za pomocą przelewu na konto: SANTANDER 23 1090 1317 0000 0001
          1437 2833 oraz w formie gotówki na stanicy WOPR.
        </p>
        <p>
          <b>Jaki jest koszt kursu na sternika motorowodnego?</b>
          <br></br> Koszt pełnego kursu wraz z egzaminem wynosi 950 złotych. W
          przypadku uczniów z ważną legitymacją koszt wynosi 750 złotych na
          chwilę obecną.
        </p>
        <p>
          <b>
            Jaki jest koszt kursu licencji na holowanie narciarza lub innych
            obiektów pływających?
          </b>
          <br></br> Koszt pełnego kursu wraz z egzaminem wynosi 700 złotych.
        </p>
      </div>
    </div>
  );
};

export default ContactItem;
