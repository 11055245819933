import { Suspense, useState } from "react";
import Header from "./components/header/Header";
import AboutUs from "./components/content/AboutUs";
import Posts from "./components/content/Posts";
import Sponsors from "./components/content/Sponsors";
import Footer from "./components/footer/Footer";
import styles from "./App.module.css";
import DocumentModal from "./components/content/DocumentsModal";
import CourseModal from "./components/content/CourseModal";
import ContactModal from "./components/content/ContactModal";
const NavigationBar = ({
  openAboutUsModal,
  openDocumentsModal,
  openCourseModal,
  openContactModal,
}) => {
  const handleNavigationClick = (sectionId) => {
    if (sectionId === "aboutUs") {
      openAboutUsModal();
    } else if (sectionId === "documents") {
      openDocumentsModal();
    } else if (sectionId === "courses") {
      openCourseModal();
    } else if (sectionId === "contact") {
      openContactModal();
    } else {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <div className={styles.navigationBar}>
      <ul>
        <li onClick={() => handleNavigationClick("posts")}>Aktualności</li>
        <li onClick={() => handleNavigationClick("contact")}>Kontakt</li>
        <li onClick={() => handleNavigationClick("aboutUs")}>Zarząd</li>
        <li onClick={() => handleNavigationClick("documents")}>Dokumenty</li>
        <li onClick={() => handleNavigationClick("courses")}>Kursy</li>
        <li onClick={() => handleNavigationClick("sponsors")}>Partnerzy</li>
      </ul>
    </div>
  );
};
const App = () => {
  const [openAboutUsModal, setOpenAboutUsModal] = useState(false);
  const [openDocumentsModal, setOpenDocumentsModal] = useState(false);
  const [openCourseModal, setOpenCourseModal] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  return (
    <Suspense fallback={null}>
      <NavigationBar
        openAboutUsModal={() => setOpenAboutUsModal(true)}
        openDocumentsModal={() => setOpenDocumentsModal(true)}
        openCourseModal={() => setOpenCourseModal(true)}
        openContactModal={() => setOpenContactModal(true)}
      />
      <Header />
      <div id="posts" className={styles.content}>
        <AboutUs open={openAboutUsModal} setOpen={setOpenAboutUsModal} />
        <Posts />
        <DocumentModal
          openModal={openDocumentsModal} // Przekazujemy stan otwarcia modala
          closeModal={() => setOpenDocumentsModal(false)} // Funkcja do zamykania modala
        />
        <CourseModal
          openModal={openCourseModal}
          closeModal={() => setOpenCourseModal(false)}
        />
        <ContactModal
          openModal={openContactModal}
          closeModal={() => setOpenContactModal(false)}
        />
        <div id="sponsors">
          <Sponsors />
        </div>
        <Footer />
      </div>
    </Suspense>
  );
};

export default App;
