import React from "react";
import styles from "./CourseModal.module.css";
import { useTranslation } from "react-i18next";

const CourseItem = (props) => {
  const { t } = useTranslation(["common"]);
  return (
    <div className={styles.staffItem}>
      <div className={styles.imgContainer}>
        <img src={props.imgSrc} alt={props.title} />
      </div>
      <div className={styles.contentContainer}>
        <h3>{props.document_title}</h3>
        <p className={styles.documentInfo}>
          {props.contentTitle}
          <ul>
            <li>{props.contentInfo1}</li>
            <li>{props.contentInfo2}</li>
            <li>{props.contentInfo3}</li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default CourseItem;
