import { Modal } from "antd";
import styles from "./DocumentsModal.module.css";
import DocumentItem from "./DocumentsItem";
import { useTranslation } from "react-i18next";

const staffContent = [
  {
    document_title: "Statut",
    document_link: "STATUT",
  },
  {
    document_title: "Wniosek o ponowne przyjęcie",
    document_link: "WNIOSEK_PONOWNE_PRZYJECIE",
  },
  {
    document_title: "Wniosek karta identyfikacyjna",
    document_link: "WNIOSEK_KARTA_IDENTYFIKACYJNA",
  },
  {
    document_title: "Wniosek o przyjęcie",
    document_link: "WNIOSEK_PRZYJECIE",
  },
  {
    document_title: "Aktualizacja danych osobowych",
    document_link: "AKTUALIZACJA_DANYCH_OSOBOWYCH",
  },
];

const DocumentModal = (props) => {
  const { t } = useTranslation(["common"]);
  const modalStyle = {
    fontFamily: "Quicksand, sans-serif",
    backgroundColor: "#f6f5f5",
    zoom: "67%",
    overflowX: "hidden",
  };
  return (
    <Modal
      style={modalStyle}
      content={<span className={modalStyle}></span>}
      title={t("Dokumenty")}
      centered
      open={props.openModal}
      onCancel={props.closeModal}
      width={1000}
      footer={
        <a key="close" onClick={props.closeModal} className={styles.close}>
          {t("close")}
        </a>
      }
    >
      {staffContent.map((item) => {
        return (
          <DocumentItem
            key={item.document_title}
            document_title={item.document_title}
            document_link={item.document_link}
          />
        );
      })}
    </Modal>
  );
};

export default DocumentModal;
