import { Modal } from "antd";
import styles from "./CourseModal.module.css";
import { useTranslation } from "react-i18next";
import ContactItem from "./ContactItem";
import photo1 from "../../assets/courses/sternik.jpg";
import photo2 from "../../assets/courses/ratownik.jpg";
const staffContent = [
  {
    document_title: "Biuro WOPR:",
  },
];

const ContactModal = (props) => {
  const { t } = useTranslation(["common"]);
  const modalStyle = {
    fontFamily: "Quicksand, sans-serif",
    backgroundColor: "#f6f5f5",
    zoom: "67%",
    overflowX: "hidden",
  };
  return (
    <Modal
      style={modalStyle}
      content={<span className={modalStyle}></span>}
      title={t("Kontakt")}
      centered
      open={props.openModal}
      onCancel={props.closeModal}
      width={1000}
      footer={
        <a key="close" onClick={props.closeModal} className={styles.close}>
          {t("close")}
        </a>
      }
    >
      {staffContent.map((item) => {
        return (
          <ContactItem
            key={item.document_title}
            document_title={item.document_title}
          />
        );
      })}
    </Modal>
  );
};

export default ContactModal;
