import React from "react";
import { Carousel } from "antd";
import photo1 from "../../assets/landing/WOPRChodziez11.jpg";
import photo2 from "../../assets/landing/WOPRChodziez5.jpg";
import photo3 from "../../assets/landing/WOPRChodziez12.jpg";
import photo4 from "../../assets/landing/WOPRChodziez13.jpg";
import photo5 from "../../assets/landing/WOPRChodziez14.jpg";
import photo6 from "../../assets/landing/zakonczenie.jpg";
import styles from "./CarouselHeader.module.css";

const CarouselHeader = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    effect: "fade",
  };

  return (
    <Carousel {...settings} className={styles.carouselStyle}>
      <div>
        <img src={photo3} alt="Photo3" />
      </div>
      <div>
        <img src={photo2} alt="Photo2" />
      </div>
      <div>
        <img src={photo1} alt="Photo1" />
      </div>
      <div>
        <img src={photo4} alt="Photo1" />
      </div>
      <div>
        <img src={photo5} alt="Photo1" />
      </div>
      <div>
        <img src={photo6} alt="Photo1" />
      </div>
    </Carousel>
  );
};
export default CarouselHeader;
