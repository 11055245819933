import styles from "./Sponsors.module.css";
import { useTranslation } from "react-i18next";
// sponsors
import s1 from "../../assets/sponsors/chodziez.png";
import s2 from "../../assets/sponsors/pzmwinw.png";
import s3 from "../../assets/sponsors/wopr_wielkopolska.jpg";
import s4 from "../../assets/sponsors/logo_starostwo.png";
import s5 from "../../assets/sponsors/dlrg.png";
import s6 from "../../assets/sponsors/mosir.png";
import s7 from "../../assets/sponsors/gtw_marine.jpg";
import s8 from "../../assets/sponsors/cezar.png";
import s9 from "../../assets/sponsors/gibas.jpg";
import s10 from "../../assets/sponsors/dumal.jpg";
import s11 from "../../assets/sponsors/wszech_dach.png";
const Sponsors = () => {
  const { t } = useTranslation(["common"]);

  return (
    <div className={styles.sponsorsDiv}>
      <h2>{t("sponsors")}</h2>
      <div className={styles.allSponsors}>
        <img src={s1} alt="Nazwa firmy" />
        <img src={s2} alt="Nazwa firmy" />
        <img src={s3} alt="Nazwa firmy" />
        <img src={s4} alt="Nazwa firmy" />
        <img src={s11} alt="Nazwa firmy" />
        <img src={s6} alt="Nazwa firmy" />
        <img src={s7} alt="Nazwa firmy" />
        <img src={s8} alt="Nazwa firmy" />
        <img src={s9} alt="Nazwa firmy" />
        <img src={s10} alt="Nazwa firmy" />
        <img src={s5} alt="Nazwa firmy" />
      </div>
    </div>
  );
};

export default Sponsors;
