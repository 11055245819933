import { Modal } from "antd";
import styles from "./CourseModal.module.css";
import { useTranslation } from "react-i18next";
import CourseItem from "./CourseItem";
import photo1 from "../../assets/courses/sternik.jpg";
import photo2 from "../../assets/courses/ratownik.jpg";
import photo3 from "../../assets/courses/narciarz.png";
const staffContent = [
  {
    document_title: "Kurs na patent sternika motorowodnego",
    imgSrc: photo1,
    contentTitle:
      "Wymagania konieczne do uzyskania patentu sternika motorowodnego:",
    contentInfo1: "ukończone 14 lat przed dniem przystąpienia do egzaminu.",
    contentInfo2: "osoby niepełnoletnie muszą mieć pisemną zgodę rodziców",
    contentInfo3:
      "zdanie egzaminu teoretycznego i praktycznego na patent sternika motorowodnego",
  },
  {
    document_title: "Kurs na ratownika wodnego",
    imgSrc: photo2,
    contentTitle:
      "Wkrótce odbędzie się kurs na stopnie ratownicze. W trakcie szkolenia będzie możliwość uzyskania następujących stopni:",
    contentInfo1: "Młodszy Ratownik WOPR (od 12 lat)",
    contentInfo2: "Ratownik wodny – MSW (od 17 lat)",
    contentInfo3:
      "Szczegółowe informacje oraz możliwość zapisu uzyskają państwo u instruktorów.",
  },
  {
    document_title:
      "Kurs na licencje holowania narciarz lub innych obiektów wodnych",
    imgSrc: photo3,
    contentTitle: "Wymagania konieczne do uzyskania licencji:",
    contentInfo1: "Ukończony 18 rok życia",
    contentInfo2: "Posiadanie patentu co najmniej sternika motorowodnego",
    contentInfo3:
      "Zdanie egzaminu na licencję na holowanie narciarza lub innych obiektów pływających.",
  },
];

const CourseModal = (props) => {
  const { t } = useTranslation(["common"]);
  const modalStyle = {
    fontFamily: "Quicksand, sans-serif",
    backgroundColor: "#f6f5f5",
    zoom: "67%",
    overflowX: "hidden",
  };
  return (
    <Modal
      style={modalStyle}
      content={<span className={modalStyle}></span>}
      title={t("Kursy motorowodne i ratownicze")}
      centered
      open={props.openModal}
      onCancel={props.closeModal}
      width={1000}
      footer={
        <a key="close" onClick={props.closeModal} className={styles.close}>
          {t("close")}
        </a>
      }
    >
      {staffContent.map((item) => {
        return (
          <CourseItem
            key={item.document_title}
            document_title={item.document_title}
            imgSrc={item.imgSrc}
            contentTitle={item.contentTitle}
            contentInfo1={item.contentInfo1}
            contentInfo2={item.contentInfo2}
            contentInfo3={item.contentInfo3}
          />
        );
      })}
    </Modal>
  );
};

export default CourseModal;
