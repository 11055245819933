import React from "react";
import styles from "./DocumentsModal.module.css";
import { useTranslation } from "react-i18next";

const DocumentItem = (props) => {
  const { t } = useTranslation(["common"]);
  const handleDownload = () => {
    const filePath = `${props.document_link}.pdf`;
    fetch(filePath)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        console.log(response);
        return response.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${props.document_link}.pdf`;
        a.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error during file download:", error);
      });
  };
  return (
    <div className={styles.staffItem}>
      <div className={styles.modalContent}>
        <div className={styles.contentContainer}>
          <h3>{props.document_title}</h3>
        </div>
        <button className={styles.downloadButton} onClick={handleDownload}>
          {t("common:Pobierz")}
        </button>
      </div>
    </div>
  );
};

export default DocumentItem;
