import { Modal } from "antd";
import styles from "./StaffModal.module.css";
import StaffItem from "./StaffItem";
import { useTranslation } from "react-i18next";
import photo1 from "../../assets/zarzad/Bezy.png";
import photo2 from "../../assets/zarzad/Wera.png";
import photo3 from "../../assets/zarzad/Majas.png";
import photo4 from "../../assets/zarzad/Tomek.png";
import photo5 from "../../assets/zarzad/Czarek.png";

const staffContent = [
  {
    title: "Maciej Bielecki",
    paragraph: "aboutUs.staff1.description",
    imgSrc: photo1,
  },
  {
    title: "Weronika Nowak",
    paragraph: "aboutUs.staff2.description",
    imgSrc: photo2,
  },
  {
    title: "Krzysztof Majewski",
    paragraph: "aboutUs.staff3.description",
    imgSrc: photo3,
  },
  {
    title: "Cezary Strumnik",
    paragraph: "aboutUs.staff4.description",
    imgSrc: photo5,
  },
  {
    title: "Tomasz Hepner",
    paragraph: "aboutUs.staff5.description",
    imgSrc: photo4,
  },
];

const StaffModal = (props) => {
  const { t } = useTranslation(["common"]);

  return (
    <Modal
      style={{
        fontFamily: "Quicksand, sans-serif",
        zoom: "67%",
        overflowX: "hidden",
      }}
      title={t("aboutUs.staff")}
      centered
      open={props.openModal}
      onCancel={props.closeModal}
      width={1000}
      footer={
        <a key="close" onClick={props.closeModal} className={styles.close}>
          {t("close")}
        </a>
      }
    >
      {staffContent.map((item) => {
        return (
          <StaffItem
            key={item.title}
            title={item.title}
            imgSrc={item.imgSrc}
            paragraph={item.paragraph}
          />
        );
      })}
    </Modal>
  );
};

export default StaffModal;
